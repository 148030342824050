import "./App.css";
import fullLogo from "./images/logo_bandeau.png";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link,
	Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import Programme from "./pages/Programme";
import Contact from "./pages/Contact";
import { stack as Menu } from "react-burger-menu";
import React, { useState } from "react";

function App() {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	// Fonction pour ouvrir/fermer le menu
	const handleStateChange = (state) => setIsMenuOpen(state.isOpen);

	// Fonction pour fermer le menu lors de la sélection d'un lien
	const closeMenu = () => setIsMenuOpen(false);

	return (
		<Router>
			<div className="container">
				<header>
					<div
						style={{
							width: "40%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							alignContent: "center",
						}}
					>
						<Link to="/Home">
							<img src={fullLogo} alt="Gymnote Logo" className="logo-image" />
						</Link>
						{window.innerWidth < 769 && (
							<Menu
								right
								isOpen={isMenuOpen}
								onStateChange={handleStateChange}
								className="mobile-menu"
							>
								<Link to="/Home" className="menu-item" onClick={closeMenu}>
									Concept
								</Link>
								<Link to="/Programme" className="menu-item" onClick={closeMenu}>
									Programmes
								</Link>
								<Link to="/Contact" className="menu-item" onClick={closeMenu}>
									Contact
								</Link>
							</Menu>
						)}
					</div>
					{/* Menu de navigation en version PC */}
					<div style={{ width: "25%" }} className="menu desktop-menu">
						<Link to="/Home" className="menu-item">
							Concept
						</Link>
						<div
							style={{ height: "80%", borderRight: "1px solid white" }}
						></div>
						<Link to="/Programme" className="menu-item">
							Programmes
						</Link>
						<div
							style={{ height: "80%", borderRight: "1px solid white" }}
						></div>
						<Link to="/Contact" className="menu-item">
							Contact
						</Link>
					</div>
				</header>
				<main>
					<Routes>
						<Route path="/" element={<Navigate to="/Home" />} />
						<Route path="/Home" element={<Home />} />
						<Route path="/Programme" element={<Programme />} />
						<Route path="/Contact" element={<Contact />} />
					</Routes>
				</main>
				<div style={{ textAlign: "center" }}>
					<p>Copyright 2025 Gymnote</p>
				</div>
			</div>
		</Router>
	);
}

export default App;
