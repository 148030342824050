import React from "react";
import "../App.css";
import LogoText from "../utils";

function Programme() {
	return (
		<div className="page">
			<div className="page-content">
				<div className="page-content-row">
					<div
						style={{
							textAlign: "center",
							width: "100%",
							alignContent: "center",
						}}
					>
						<h1>
							Télécharge ton premier programme <LogoText /> !
						</h1>
						<p>
							Vous avez un objectif physique ou de performance mais vous débutez
							dans la musculation ? Pas de problèmes, <LogoText /> vous offre
							votre premier programme !
						</p>
						<a
							href="/fichiers/HalfBody-GymNote.pdf"
							download="HalfBody-GymNote.pdf"
							className="download-button"
						>
							Télécharger le programme en PDF
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Programme;
