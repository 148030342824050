import React from "react";
import "./App.css";

function LogoText() {
	return (
		<span>
			<span className="logo-color">G</span>ym
			<span className="logo-color">N</span>ote
		</span>
	);
}

export default LogoText;
